<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      width="60%"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
      class="overflow-hidden"
    >
      <v-card class="overflow-hidden">
        <v-card-title class="pa-0">
          <v-row
            no-gutters
            :class="
              $vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-3 pl-4 pr-2'
            "
          >
            <span
              class="font-weight-medium grey--text text--darken-4 text-h6"
              >{{ $t("Review") }}</span
            >
            <v-spacer></v-spacer>
            <v-btn
              class="col-1 my-auto"
              aria-label="cross"
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
        </v-card-title>
        <v-divider v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-card-text style="height: 85%" class="pa-0">
          <v-container fluid class="pa-0 mb-16">
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 pl-4'"
              >{{ $t("User") }} :
              <span
                class="pa-0 font-weight-bold grey--text text--darken-4 ml-2"
              >
                {{ approvalDetails.approver }}</span
              >
              <v-col
                cols="1"
                :class="$vuetify.breakpoint.mdAndUp ? 'px-10' : 'px-5'"
              >
                <v-divider vertical></v-divider
              ></v-col>
              <span class="pa-0">{{ $t("Created On") }} : </span
              ><span
                class="pa-0 font-weight-bold grey--text text--darken-4 ml-2"
              >
                {{ approvalDetails.received_date }}</span
              ></v-row
            >
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 pl-4'"
            >
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
                >{{ $t("excavationLocation") }}</span
              >
            </v-row>
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : 'pt-2 pl-8'"
            >
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2"
                >{{
                  ticketData ? ticketData.street + "," + ticketData.place : ""
                }}</span
              >
            </v-row>
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.smAndDown ? '' : 'pt-2 pl-6'"
              class="pt-2"
            >
              <MapView v-if="isMapView" :map-size="true" />
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              no-gutters
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
              class="mb-2"
            >
              <v-col> <v-divider></v-divider></v-col
              ><v-col v-if="$vuetify.breakpoint.mdAndUp" cols="5"> </v-col>
            </v-row>
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
            >
              <v-col>
                <v-row no-gutters class="mt-2">
                  <span
                    class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
                    >{{ $t("811Center") }}</span
                  >
                </v-row>
                <v-row
                  no-gutters
                  :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 pb-2' : 'py-3'"
                >
                  <v-col lg="4" md="4" xl="4">
                    <img
                      v-if="center === 'JULIE'"
                      class="pa-4"
                      style="border: 1px solid #e0e0e0; border-radius: 8%"
                      src="@/assets/images/julie-811-center.svg"
                      role="img"
                      alt="Julie 811 Center Logo"
                    />
                    <img
                      v-if="center === 'OUPS'"
                      class="pa-4"
                      style="border: 1px solid #e0e0e0; border-radius: 8%"
                      src="@/assets/images/ohio-811.svg"
                      role="img"
                      alt="Ohio 811 Center Logo"
                    />
                    <img
                      v-if="center === 'UTAH'"
                      class="pa-4"
                      style="border: 1px solid #e0e0e0; border-radius: 8%"
                      src="@/assets/images/utah-811.svg"
                      role="img"
                      alt="UTAH 811 Center Logo"
                    />
                    <img
                      v-if="center === 'NCOCC'"
                      class="py-4"
                      src="@/assets/images/NC811.svg"
                      role="img"
                      alt="NC 811 Center Logo"
                    />
                    <img
                      v-if="center === 'UNCC'"
                      class="pa-4"
                      style="border: 1px solid #e0e0e0; border-radius: 8%"
                      src="@/assets/images/CO811.svg"
                      role="img"
                      alt="Colorado 811 Center Logo"
                    />
                    <img
                      v-if="center === 'VUPS'"
                      class="pa-4"
                      style="border: 1px solid #e0e0e0; border-radius: 8%"
                      src="@/assets/images/VA811.svg"
                      role="img"
                      alt="VA 811 Center Logo"
                    />
                    <img
                      v-if="center === 'USAS'"
                      class="pa-4"
                      style="border: 1px solid #e0e0e0; border-radius: 8%"
                      src="@/assets/images/USAS-digalert-logo.svg"
                      role="img"
                      alt="USAS Digalert Logo"
                    />
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.mdAndUp ? 'mt-1' : 'ml-10'"
                  >
                    <v-row no-gutters>
                      <span
                        class="font-weight-normal grey--text text--darken-1 text-caption"
                        >{{ $t("excavatorType") }}</span
                      >
                    </v-row>
                    <v-row no-gutters>
                      <span
                        class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                        >{{ companyExcavatorType }}</span
                      >
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-2">
                  <v-divider></v-divider>
                </v-row>

                <v-row no-gutters>
                  <v-col class="mt-4 mb-10">
                    <v-row class="mb-2" no-gutters>
                      <span
                        class="font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-1"
                        >{{ $t("excavationDetails") }}</span
                      >
                    </v-row>
                    <TicketContactDetails
                      :excavation-details="excavationDetails"
                    />
                    <OhioReviewTicket
                      v-if="center === 'OUPS'"
                      :excavation-details="excavationDetails"
                    />
                    <JulieRevieTicket
                      v-if="center === 'JULIE'"
                      :excavation-details="excavationDetails"
                    />
                    <UtahReviewTicket
                      v-if="center === 'UTAH'"
                      :excavation-details="excavationDetails"
                    />
                    <NcReviewTicktet
                      v-if="center === 'NCOCC'"
                      :excavation-details="excavationDetails"
                    />
                    <UnccReviewTicktet
                      v-if="center === 'UNCC'"
                      :excavation-details="excavationDetails"
                    />
                    <UsasReviewTicket
                      v-if="center === 'USAS'"
                      :excavation-details="excavationDetails"
                    />
                    <VupsReviewTicket
                      v-if="center === 'VUPS'"
                      :excavation-details="excavationDetails"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="5"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-bottom-navigation class="py-2 px-2">
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

          <v-btn
            color="red"
            :outlined="$vuetify.breakpoint.mdAndUp"
            class="text-none rounded-lg elevation-0 mr-4"
            depressed
            :width="!$vuetify.breakpoint.mdAndUp ? '200' : '90'"
            height="40"
            @click="rejectDialog = true"
          >
            <span
              :class="$vuetify.breakpoint.mdAndUp ? 'red--text' : 'white--text'"
            >
              {{ $t("Reject") }}</span
            >
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            :width="!$vuetify.breakpoint.mdAndUp ? '200' : '220'"
            height="40"
            depressed
            :disabled="isApproved"
            @click="approveTicket"
          >
            {{ $t("Approve and Submit") }}
            <span>
              <v-progress-circular
                v-if="showLoader"
                indeterminate
                size="15"
                color="grey"
                class="ml-2"
              ></v-progress-circular>
            </span>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
    </v-dialog>
    <RejectDialog
      v-if="rejectDialog"
      :dialog="rejectDialog"
      @rejectTicket="rejectTicket"
      @closeDialog="closeDialog"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {
    TicketContactDetails: () => import("../TicketContactDetails.vue"),
    OhioReviewTicket: () => import("../centers/ohio/OhioReviewTicket.vue"),
    JulieRevieTicket: () => import("../centers/julie/JulieRevieTicket.vue"),
    UtahReviewTicket: () => import("../centers/utah/UtahReviewTicket.vue"),
    RejectDialog: () => import("./RejectDialog.vue"),
    MapView: () => import("../MapView.vue"),
    NcReviewTicktet: () => import("../centers/ncocc/NcReviewTicktet.vue"),
    UnccReviewTicktet: () => import("../centers/uncc/UnccReviewTicktet.vue"),
    UsasReviewTicket: () => import("../centers/usas/UsasReviewTicket.vue"),
    VupsReviewTicket: () => import("../centers/vups/VupsReviewTicket.vue"),
  },
  props: {
    usersMap: {
      type: Object,
      default: () => {},
    },
    approvalDetails: {
      type: Object,
      default: () => {},
    },
    mapView: {
      type: Object,
      default: () => {},
    },
    center: {
      type: String,
      default: () => "JULIE",
    },
    excavationDetails: {
      type: Object,
      default: () => {},
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: () => true,
    },
    approvalId: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      rejectDialog: false,
      isMapView: false,
      isApproved: false,
      showLoader: false,
    };
  },
  computed: {
    companyExcavatorType() {
      let excavatorType = "";
      if (
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        excavatorType = this.$store.state.Company.company.centers.filter(
          (item) => {
            return (
              this.$store.state.User.centerMap[item.center_id].abbrev ===
              this.center
            );
          },
        )[0].excavator_type;
      }
      return excavatorType;
    },
  },
  created() {
    this.moment = moment;
    setTimeout(() => {
      this.isMapView = true;
    }, 500);
  },
  methods: {
    onBackButton() {
      this.$store.commit("setMapData", {});
      this.$store.commit("setTicketData", {});
      this.$emit("reviewTicketBack");
    },
    submitTicket() {
      this.$store.commit("setMapData", {});
      this.$store.commit("setTicketData", {});
      this.$emit("submitTicket");
    },
    approveTicket() {
      this.isApproved = true;
      this.showLoader = true;
      this.$emit("submitTicket", this.approvalDetails, this.usersMap);
    },
    scheduleTicketDialog() {
      this.scheduleDialog = true;
    },
    async rejectTicket(rejectObject) {
      rejectObject.approval_id = this.approvalId;
      rejectObject.status = "rejected";
      const approvalData = await this.$store.dispatch(
        "updateApproval",
        rejectObject,
      );
      if (approvalData.status !== "error") {
        this.$emit("closeDialog");
      }
    },
    closeDialog() {
      this.rejectDialog = false;
    },
  },
};
</script>
