<template>
  <div>
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-card-title class="pr-0 py-2">
        <v-row>
          <span
            class="text-subtitle-1 font-weight-bold my-auto"
            :class="
              $vuetify.breakpoint.mdAndUp ? 'ml-4 text-h5' : 'ml-1 text-h6'
            "
            >{{ $t("pendingApproval") }}
            <v-btn
              elevation="0"
              fab
              outlined
              width="25"
              height="25"
              class="ml-3"
              color="primary"
            >
              {{ totalPendingApproval }}</v-btn
            ></span
          >
          <v-spacer></v-spacer>
          <v-col
            cols="4"
            lg="2"
            md="2"
            xl="2"
            :class="
              !$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6 px-10 py-4'
            "
          >
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
              hide-details
              flat
              solo
              dense
              height="30"
              required
              label=""
              :aria-label="selectedStatus"
              @change="filterApprovals"
            >
            </v-select
          ></v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6 py-4'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="approvalData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        item-key="email"
        color="primary"
        class="elevation-0 pa-0"
        :class="reviewDialog || isTicketSubmitted ? null : 'awaiting-table'"
        checkbox-color="primary"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading="
          approvalData.length == 0 && !isTableEmpyt && selectedStatus === 'All'
        "
        loading-text="Loading... Please wait"
        mobile-breakpoint="950"
        disable-sort
      >
        <template v-slot:[`item.center_ticket_id`]="{ item }">
          <v-row class="text-subtitle-2 font-weight-bold ma-0">
            <a class="primary--text text-decoration-none">
              {{ item.center_ticket_id }}</a
            >
          </v-row>
          <v-row class="ma-0"> {{ item.email }}</v-row>
        </template>
        <template v-slot:[`item.priority`]="{ item }">
          <div class="nowrap">
            <v-icon small :color="priorityIconColor(item.priority)">
              {{ priorityIcon(item.priority) }}
            </v-icon>
            {{ item.priority }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" small class="white--text">
            {{
              item.status === "pending" ? "Pending Approval" : $t(item.status)
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.review`]="{ item }">
          <v-btn
            :disabled="item.status !== 'pending'"
            outlined
            color="primary"
            @click="reviewTicket(item)"
            >Review
          </v-btn>
        </template>

        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.email" class="pb-4">
              <v-card height="300" elevation="0">
                <v-row>
                  <v-col class="pr-0">
                    <v-chip
                      x-small
                      :color="getColor(item.status)"
                      class="white--text mr-4"
                    >
                      {{
                        item.status === "pending"
                          ? "Pending Approval"
                          : $t(item.status)
                      }}
                    </v-chip>
                  </v-col>
                </v-row>

                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("Date Review") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{
                    $t(item.received_date)
                  }}</v-col>
                </v-row>
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("user") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.approver }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("comments") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.comments }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("priority") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.priority }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("type") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.type }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("center") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.center_name }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("address") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.street }},{{ item.place }},{{ item.county }},{{
                      item.state
                    }}</v-col
                  ></v-row
                >
                <v-row class="ml-1 pt-4 pl-3 pr-4 mb-4">
                  <v-btn
                    :disabled="item.status !== 'pending'"
                    outlined
                    block
                    color="primary"
                    @click="reviewTicket(item)"
                    >Review
                  </v-btn>
                </v-row>
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <ReviewApproval
      v-if="reviewDialog"
      :excavation-details="reviewObject.excavationDetails"
      :center="reviewObject.center"
      :ticket-data="reviewObject.ticketData"
      :dialog="reviewDialog"
      :approval-id="reviewObject.approval_id"
      :approval-details="reviewObject.approvalDetails"
      :map-view="reviewObject.mapView"
      :users-map="usersMap"
      @closeDialog="closeDialog"
      @submitTicket="submitTicket"
    />
    <TicketSubmitMessage
      v-if="isTicketSubmitted"
      :dialog="isTicketSubmitted"
      :ticket-number="ticketNumber"
      @onCloseDialog="onCloseTicketAcknowledgement"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import ReviewApproval from "./ReviewApproval.vue";
import TicketSubmitMessage from "../TicketSubmitMessage.vue";
import EditTicket from "@/mixins/EditTicket";
import ErrorMessage from "../../../molecules/ErrorMessage.vue";
export default {
  components: {
    ReviewApproval: () => import("./ReviewApproval.vue"),
    TicketSubmitMessage: () => import("../TicketSubmitMessage.vue"),
    ErrorMessage: () => import("../../../molecules/ErrorMessage.vue"),
  },
  mixins: [EditTicket],
  data() {
    return {
      isTableEmpyt: false,
      errorCode: "",
      errorMessage: "",
      isTicketSubmitted: false,
      isError: false,
      search: "",
      selected: [],
      approvalData: [],
      reviewDialog: false,
      reviewObject: { excavationDetails: {}, center: "", ticketData: {} },
      headers: [
        {
          text: "Date Received",
          value: "received_date",
          width: "200",
        },
        { text: "User", value: "approver", width: "200" },
        { text: "Status", value: "status", width: "20%" },
        { text: "Comments", value: "comments", width: "200" },
        { text: "Ticket Number", value: "center_ticket_id", width: "200" },
        { text: "Priority", value: "priority", width: "100" },
        { text: "Type", value: "type", width: "80" },
        { text: "Center", value: "center_name", width: "" },
        { text: "Street Address", value: "street", width: "200" },
        { text: "Place", value: "place", width: "120" },
        { text: "County", value: "county", width: "80" },
        { text: "State", value: "state", width: "80" },
        { text: "", value: "review", width: "100", align: "center" },
      ],
      usersMap: {},
      statusOptions: ["All", "Pending", "Approved", "Rejected"],
      selectedStatus: "All",
      allApprovalData: [],
    };
  },
  computed: {
    totalPendingApproval() {
      let count = 0;
      this.allApprovalData.forEach((item) => {
        if (item.status === "pending") count++;
      });
      return count;
    },
    userId() {
      return this.$store.state.User.user.user_id;
    },
  },
  watch: {
    userId() {
      this.getAwaitingApprovals();
    },
  },
  created() {
    this.moment = moment;
    this.getAwaitingApprovals();
  },
  methods: {
    priorityIconColor(priority) {
      switch (priority) {
        case "NORM":
          return "grey";
        case "RUSH":
          return "#fdd835";
        case "EMER":
          return "red";
        case "NONC":
          return "light-blue";
        default:
          return "";
      }
    },
    priorityIcon(priority) {
      switch (priority) {
        case "NORM":
          // gray circle exclamation
          return "mdi-circle";
        case "RUSH":
          // yellow circle exclamation
          return "mdi-bell";
        case "EMER":
          // red bell exclamation
          return "mdi-bell";
        case "NONC":
          // light blue circle exclamation
          return "mdi-bell";
        default:
          return "";
      }
    },
    async onCloseTicketAcknowledgement() {
      this.isTicketSubmitted = false;
      await this.getAwaitingApprovals();
      this.filterApprovals();
      this.ticketNumber = "";
    },
    async closeDialog() {
      this.reviewDialog = false;
      await this.getAwaitingApprovals();
      this.filterApprovals();
    },
    async reviewTicket(item) {
      this.reviewObject.excavationDetails = item.options.excavationDetails;
      this.reviewObject.ticketData = item.options.ticketData;
      this.reviewObject.center = item.center;
      this.reviewObject.approval_id = item.approval_id;
      this.reviewObject.approvalDetails = item;
      this.reviewObject.mapView = item.options.geoJson;
      await this.$store.commit("setMapData", this.reviewObject.mapView);
      await this.$store.commit("setTicketData", item.options.ticketData);
      this.reviewDialog = true;
    },
    filterApprovals() {
      this.approvalData = [];
      if (this.selectedStatus === "All") {
        this.approvalData = this.allApprovalData;
      } else {
        this.allApprovalData.forEach((item) => {
          if (this.selectedStatus.toLowerCase() === item.status)
            this.approvalData.push(item);
        });
      }
      if (this.approvalData.length === 0) this.isTableEmpyt = true;
    },
    getColor(status) {
      if (status.toLowerCase() === "pending") return "#C35309";
      else if (status.toLowerCase() === "rejected") return "red";
      else return "#1E853A";
    },
    async getAwaitingApprovals() {
      const userData = await this.$store.dispatch("getCompanyUsers", {
        company_id: this.$store.state.User.user.company_id,
      });
      if (userData.status !== "error") {
        userData.forEach((item) => {
          this.usersMap[item.user_id] = item;
        });
      }
      const approvalData = await this.$store.dispatch("getPendingApprovals", {
        user_id: this.userId,
      });
      if (approvalData.status !== "error") {
        approvalData.sort(function (a, b) {
          return new Date(b.received_date) - new Date(a.received_date);
        });
        approvalData.forEach((item) => {
          item.received_date = this.moment(item.received_date).format(
            "MM/DD/YYYY,hh:mm A",
          );
          if (item.comments.length > 0)
            item.comments = item.comments[item.comments.length - 1].comments;
          else item.comments = "";
          item.center = this.$store.state.User.centerMap[item.center_id].abbrev;
          item.center_name =
            this.$store.state.User.centerMap[item.center_id].center_name;
          if (this.usersMap[item.approver_user_id])
            item.approver = this.usersMap[item.sender_user_id].name;
          if (item.options && item.options.ticketData) {
            item.street = item.options.ticketData.street;
            item.place = item.options.ticketData.place;
            item.county = item.options.ticketData.county;
            item.state = item.options.ticketData.state;
          }
        });
        this.allApprovalData = approvalData;
        this.approvalData = approvalData;
        if (this.approvalData.length === 0) this.isTableEmpyt = true;
      }
    },
  },
};
</script>

<style lang="scss">
.awaiting-table {
  table > tbody > tr > td:nth-child(13),
  table > thead > tr > th:nth-child(13) {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    z-index: 9998;
    background: white;
  }
  table > thead > tr > th:nth-child(13) {
    z-index: 9999;
  }
}
</style>
