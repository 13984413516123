var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "overflow-hidden",
          attrs: {
            scrollable: "",
            width: "60%",
            fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-8 pt-2"
                        : "mt-3 pl-4 pr-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-medium grey--text text--darken-4 text-h6",
                        },
                        [_vm._v(_vm._s(_vm.$t("Review")))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-1 my-auto",
                          attrs: { "aria-label": "cross", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog")
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.mdAndUp ? _c("v-divider") : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "pa-0", staticStyle: { height: "85%" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0 mb-16", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "pl-8 pt-2"
                            : "mt-1 pl-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _vm._v(_vm._s(_vm.$t("User")) + " : "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "pa-0 font-weight-bold grey--text text--darken-4 ml-2",
                            },
                            [_vm._v(" " + _vm._s(_vm.approvalDetails.approver))]
                          ),
                          _c(
                            "v-col",
                            {
                              class: _vm.$vuetify.breakpoint.mdAndUp
                                ? "px-10"
                                : "px-5",
                              attrs: { cols: "1" },
                            },
                            [_c("v-divider", { attrs: { vertical: "" } })],
                            1
                          ),
                          _c("span", { staticClass: "pa-0" }, [
                            _vm._v(_vm._s(_vm.$t("Created On")) + " : "),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "pa-0 font-weight-bold grey--text text--darken-4 ml-2",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.approvalDetails.received_date)
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "pl-8 pt-2"
                            : "mt-1 pl-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("excavationLocation")))]
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.smAndDown
                            ? "pl-4"
                            : "pt-2 pl-8",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ticketData
                                    ? _vm.ticketData.street +
                                        "," +
                                        _vm.ticketData.place
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "pt-2",
                          class: _vm.$vuetify.breakpoint.smAndDown
                            ? ""
                            : "pt-2 pl-6",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _vm.isMapView
                            ? _c("MapView", { attrs: { "map-size": true } })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mb-2",
                              class: _vm.$vuetify.breakpoint.mdAndUp
                                ? "pl-8 pt-2"
                                : "mt-1 mx-5",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-col", [_c("v-divider")], 1),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c("v-col", { attrs: { cols: "5" } })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "pl-8 pt-2"
                            : "mt-1 mx-5",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-2",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-1",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("811Center")))]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  class: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "pt-5 pb-2"
                                    : "py-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { lg: "4", md: "4", xl: "4" } },
                                    [
                                      _vm.center === "JULIE"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/julie-811-center.svg"),
                                              role: "img",
                                              alt: "Julie 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "OUPS"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/ohio-811.svg"),
                                              role: "img",
                                              alt: "Ohio 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "UTAH"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/utah-811.svg"),
                                              role: "img",
                                              alt: "UTAH 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "NCOCC"
                                        ? _c("img", {
                                            staticClass: "py-4",
                                            attrs: {
                                              src: require("@/assets/images/NC811.svg"),
                                              role: "img",
                                              alt: "NC 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "UNCC"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/CO811.svg"),
                                              role: "img",
                                              alt: "Colorado 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "VUPS"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/VA811.svg"),
                                              role: "img",
                                              alt: "VA 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "USAS"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/USAS-digalert-logo.svg"),
                                              role: "img",
                                              alt: "USAS Digalert Logo",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      class: _vm.$vuetify.breakpoint.mdAndUp
                                        ? "mt-1"
                                        : "ml-10",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-normal grey--text text--darken-1 text-caption",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("excavatorType"))
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.companyExcavatorType)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-row",
                                    { staticClass: "mb-2" },
                                    [_c("v-divider")],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "mt-4 mb-10" },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mb-2",
                                          attrs: { "no-gutters": "" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("excavationDetails")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("TicketContactDetails", {
                                        attrs: {
                                          "excavation-details":
                                            _vm.excavationDetails,
                                        },
                                      }),
                                      _vm.center === "OUPS"
                                        ? _c("OhioReviewTicket", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "JULIE"
                                        ? _c("JulieRevieTicket", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "UTAH"
                                        ? _c("UtahReviewTicket", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "NCOCC"
                                        ? _c("NcReviewTicktet", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "UNCC"
                                        ? _c("UnccReviewTicktet", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "USAS"
                                        ? _c("UsasReviewTicket", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.center === "VUPS"
                                        ? _c("VupsReviewTicket", {
                                            attrs: {
                                              "excavation-details":
                                                _vm.excavationDetails,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c("v-col", { attrs: { cols: "5" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-bottom-navigation",
                { staticClass: "py-2 px-2" },
                [
                  _vm.$vuetify.breakpoint.mdAndUp ? _c("v-spacer") : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0 mr-4",
                      attrs: {
                        color: "red",
                        outlined: _vm.$vuetify.breakpoint.mdAndUp,
                        depressed: "",
                        width: !_vm.$vuetify.breakpoint.mdAndUp ? "200" : "90",
                        height: "40",
                      },
                      on: {
                        click: function ($event) {
                          _vm.rejectDialog = true
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "red--text"
                            : "white--text",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Reject")))]
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: {
                        width: !_vm.$vuetify.breakpoint.mdAndUp ? "200" : "220",
                        height: "40",
                        depressed: "",
                        disabled: _vm.isApproved,
                      },
                      on: { click: _vm.approveTicket },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("Approve and Submit")) + " "),
                      _c(
                        "span",
                        [
                          _vm.showLoader
                            ? _c("v-progress-circular", {
                                staticClass: "ml-2",
                                attrs: {
                                  indeterminate: "",
                                  size: "15",
                                  color: "grey",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.rejectDialog
        ? _c("RejectDialog", {
            attrs: { dialog: _vm.rejectDialog },
            on: {
              rejectTicket: _vm.rejectTicket,
              closeDialog: _vm.closeDialog,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }